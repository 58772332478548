import React from 'react';

import { RoleEnum } from 'containers/Auth/enums';
import { useAuth } from 'containers/Auth/hooks/useAuth';

export type AccessRoleProps = {
  accessRoles: RoleEnum[];
  children: React.ReactElement;
  fallback?: JSX.Element | null;
};

const AccessRole = ({
  accessRoles,
  children,
  fallback = null,
}: AccessRoleProps) => {
  const { hasAccess } = useAuth();

  return hasAccess(accessRoles) ? children : fallback;
};

export default AccessRole;
