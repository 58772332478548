import React from 'react';

import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

export const useStyles = makeStyles(() => ({
  root: {
    '& > *:first-child': {
      marginTop: 0,
    },
    '& > *:last-child': {
      marginBottom: 0,
    },
    '& b': {
      backgroundColor: 'yellow',
      fontWeight: 'normal',
    },
    '& img': {
      maxWidth: '100%',
    },
    '& pre': {
      '& > code': {
        padding: 0,
      },
    },
  },
}));

interface Props {
  children: string;
  className?: string;
}

export const MarkdownText = ({ children, className, ...rest }: Props) => {
  const classes = useStyles();

  return (
    <ReactMarkdown
      className={clsx(classes.root, className)}
      remarkPlugins={[remarkGfm]}
      {...rest}
    >
      {children}
    </ReactMarkdown>
  );
};
