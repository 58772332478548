import React, { FC, useMemo, useState } from 'react';

import BookmarkIcon from '@mui/icons-material/Bookmark';
import NewReleasesIcon from '@mui/icons-material/NewReleases';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import { IconButton, Popover, Theme, Tooltip, useTheme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { AppTheme } from 'app/theme';
import { useTranslation } from 'react-i18next';

import { StyledBadge } from 'common/components/Badges/StyledBadge';
import { TrackedActionContext } from 'common/components/TrackedActions/trackedAction.context';
import { TrackEventName } from 'common/components/TrackedActions/withTrackedAction.interface';
import { useAuth } from 'containers/Auth/hooks/useAuth';

import { ChangelogUpdatesNotification } from './ChangelogUpdatesNotification';
import NotificationLinkItem from './NotificationLinkItem';
import NotificationLinkItemExpandable from './NotificationLinkItemExpandable';
import { useChangelogUpdates } from './useChangelogUpdates';
import { useRecommendationsUpdates } from './useRecommendationsUpdates';
import { useTagsUpdates } from './useTagsUpdates';

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    '& > * + *': {
      borderTop: `1px solid ${theme.palette.divider}`,
    },
    minWidth: 300,
  },
}));

export const Notifications: FC = () => {
  const classes = useStyles();
  const theme = useTheme<AppTheme>();

  const { isAuthenticated } = useAuth();
  const { t } = useTranslation('common');
  const {
    changelogUpdates,
    setLatestChangelogDate,
    updateLatestChangelogDate,
  } = useChangelogUpdates();
  const { hasNewRecs, unseenRecsCount } = useRecommendationsUpdates();
  const { followingTags, hasTagsUpdates, tagsUpdatesCount } = useTagsUpdates();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const updatesCount =
    (changelogUpdates ? 1 : 0) +
    (isAuthenticated
      ? tagsUpdatesCount + (hasNewRecs ? unseenRecsCount : 0)
      : 0);
  const hasUpdates =
    changelogUpdates || (isAuthenticated && (hasTagsUpdates || hasNewRecs));

  const tagSubItems = useMemo(
    () =>
      followingTags.map((tag) => ({
        id: tag.id,
        label: tag.name,
        notifications: tag.newDocuments,
        to: `/tags/${tag.id}`,
      })),
    [followingTags]
  );

  const handlePopoverOpen = async (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    await updateLatestChangelogDate();
  };

  const handlePopoverClose = async () => {
    setAnchorEl(null);
    setTimeout(async () => {
      await setLatestChangelogDate();
    }, 300);
  };

  if (!hasUpdates) return null;

  return (
    <TrackedActionContext.Provider
      value={{ actionContext: 'Notifications toolbar' }}
    >
      <Tooltip title={t<string>('navBar.notifications.tooltipTitle')}>
        <IconButton color="contrast" size="large" onClick={handlePopoverOpen}>
          <StyledBadge badgeContent={updatesCount} color="error" max={99}>
            <NotificationsActiveIcon
              sx={{ color: theme.palette.header.iconColor }}
            />
          </StyledBadge>
        </IconButton>
      </Tooltip>

      <Popover
        anchorEl={anchorEl}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        classes={{ paper: classes.paper }}
        open={!!anchorEl}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        onClose={handlePopoverClose}
      >
        {isAuthenticated && hasTagsUpdates && (
          <NotificationLinkItemExpandable
            chipLabel={tagsUpdatesCount}
            icon={BookmarkIcon}
            subItems={tagSubItems}
            text={t<string>('navBar.tags')}
            onClick={handlePopoverClose}
          />
        )}

        {isAuthenticated && hasNewRecs && (
          <NotificationLinkItem
            chipLabel={unseenRecsCount}
            eventName={TrackEventName.RecommendationsNotificationClicked}
            icon={NewReleasesIcon}
            text={t<string>('navBar.recommendations')}
            to="/recommendations"
            onClick={handlePopoverClose}
          />
        )}

        {changelogUpdates && (
          <ChangelogUpdatesNotification onClick={handlePopoverClose} />
        )}
      </Popover>
    </TrackedActionContext.Provider>
  );
};
