import { AxiosResponse } from 'axios';

import {
  NotesApi,
  Configuration,
  NoteObjectType,
} from '@zarn/vendor/dist/saved-results';

import { PaginatedResults } from 'api/models/PaginatedResults';
import { SharingPolicies } from 'containers/Sharing/Sharing.types';
import { serializeSharingPolicy } from 'containers/Sharing/sharing.utils';
import { toTagTypeParams } from 'containers/TagNotes/tagNotes.utils';

import { SERVICE_URL, BASE_HEADERS } from '../apiConfig';
import axiosInstance from '../axiosInstance';

import {
  CreateTagNotePayload,
  ListNotesPayload,
  ListTagNotesPayload,
  NoteDetails,
  UpdateNotePayload,
  CreateNotePayload,
} from './notesApi.types';
import {
  savedNotesBackCompat,
  serializeNoteObjectType,
  serializeUpdateNotePayload,
  deserializeNoteItem,
  serializeCreateNotePayload,
} from './notesApi.utils';

export const notesApi = new NotesApi(
  new Configuration({ baseOptions: BASE_HEADERS, basePath: SERVICE_URL }),
  SERVICE_URL,
  axiosInstance
);

export const listNotes = async ({
  noteIds,
  noteObjectType,
  sortBy,
  tenant,
  withAnnotations,
  ...payload
}: ListNotesPayload): Promise<AxiosResponse<PaginatedResults<NoteDetails>>> => {
  const { data, ...rest } = await notesApi.filterNotes({
    ...payload,
    noteIds,
    noteObjectType: noteObjectType && serializeNoteObjectType(noteObjectType),
    requesterUuid: '',
    sortBy: typeof sortBy !== 'undefined' ? [sortBy] : sortBy,
    tenant,
    userRoles: '',
    withAnnotationHighlight: withAnnotations,
  });

  return {
    ...rest,
    data: new PaginatedResults(
      data.count,
      data.results.map((n) => savedNotesBackCompat(deserializeNoteItem(n))),
      data.next?.page,
      data.previous?.page,
      payload.page,
      payload.pageSize
    ),
  };
};

export const listTagNotes = async (
  payload: ListTagNotesPayload
): Promise<AxiosResponse<PaginatedResults<NoteDetails>>> => {
  const tagType = toTagTypeParams(payload.tagType);

  const { data, ...rest } = await (tagType === 'favorites'
    ? notesApi.favoriteTagNotesFilter({
        page: payload.page,
        pageSize: payload.pageSize,
        requesterUuid: '',
        tenant: payload.tenant,
        userRoles: '',
      })
    : notesApi.tagNotesFilter({
        id: payload.tagId,
        page: payload.page,
        pageSize: payload.pageSize,
        requesterUuid: '',
        tagType,
        tenant: payload.tenant,
        userRoles: '',
      }));

  return {
    ...rest,
    data: new PaginatedResults(
      data.count,
      data.results.map((e) =>
        savedNotesBackCompat(
          deserializeNoteItem({
            ...e,
            annotation_highlight: null,
            object_id: String(payload.tagId),
            object_type: NoteObjectType.Tag,
          })
        )
      ),
      data.next?.page,
      data.previous?.page,
      payload.page,
      payload.pageSize
    ),
  };
};

export const createTagNote = async (
  payload: CreateTagNotePayload
): Promise<AxiosResponse<NoteDetails>> => {
  const tagType = toTagTypeParams(payload.tagType);

  const { data, ...rest } = await (tagType === 'favorites'
    ? notesApi.createFavoriteTagNote({
        requesterUuid: '',
        tagNoteForm: {
          content: payload.content,
          sharing: serializeSharingPolicy(payload.sharing),
        },
        tenant: payload.tenant,
        userRoles: '',
      })
    : notesApi.createTagNote({
        id: payload.tagId,
        requesterUuid: '',
        tagNoteForm: {
          content: payload.content,
          sharing: serializeSharingPolicy(payload.sharing),
        },
        tagType,
        tenant: payload.tenant,
        userRoles: '',
      }));

  return {
    ...rest,
    data: savedNotesBackCompat(
      deserializeNoteItem({
        ...data,
        annotation_highlight: null,
        object_id: String(payload.tagId),
        object_type: NoteObjectType.Tag,
      })
    ),
  };
};

export const createNote = async (
  payload: CreateNotePayload
): Promise<AxiosResponse<NoteDetails>> => {
  const { data, ...rest } = await notesApi.createNote({
    noteForm: serializeCreateNotePayload(payload),
    requesterUuid: '',
    tenant: payload.tenant,
    userRoles: '',
  });

  return {
    ...rest,
    data: savedNotesBackCompat(deserializeNoteItem(data)),
  };
};

export const updateNote = async (
  payload: UpdateNotePayload
): Promise<AxiosResponse<void>> => {
  return notesApi.replaceNote({
    id: payload.noteId,
    noteForm: serializeUpdateNotePayload(payload),
    requesterUuid: '',
    tenant: payload.tenant,
    userRoles: '',
  });
};

export const deleteNote = (id: number, tenant: string) =>
  notesApi.deleteNote({ id, requesterUuid: '', tenant, userRoles: '' });

export const noteShareableToSharing = (
  shareable: boolean,
  orgId?: number
): SharingPolicies => {
  return shareable && orgId
    ? { orgs: [{ id: orgId, permission: 'read' }], users: [] }
    : { orgs: [], users: [] };
};
