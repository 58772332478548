import { OrgRole } from '../Auth.interface';
import { createMe } from '../models/createMe';

import {
  accessTokenAdminMock,
  accessTokenManagerMock,
  accessTokenMock,
} from './Auth.mock';

export const meMock = createMe(accessTokenMock);

export const meAdminMock = createMe(accessTokenAdminMock);

export const meManagerMock = createMe(accessTokenManagerMock);

export const meNoMemberMock = createMe({
  ...accessTokenMock,
  user_roles: {
    role_data: [],
    roles: [],
  },
});

export const orgRoleMock: OrgRole = {
  accountId: 2,
  accountName: 'Zeta Alpha',
  name: 'organization',
  permission: 'permission',
  userRoleId: 1,
};
