/* eslint-disable camelcase */
import {
  SearchEngineString,
  SearchPostResponse,
} from '@zarn/vendor/dist/search';

import { HitType, SearchEngineEnum } from 'common/enums';
import { deserializeSearchHit } from 'common/utils/documents';
import { RetrievalUnitData } from 'containers/RetrievalUnit/RetrievalUnitData.interface';
import { SearchPayload } from 'containers/Search/SearchPayload.interface';

import { SearchResults, SearchResultsData } from './SearchResults.interface';

export function deserializeSearchEngineString(
  searchEngine: SearchEngineString
): SearchEngineEnum {
  switch (searchEngine) {
    case 'google_scholar':
      return SearchEngineEnum.GoogleScholar;
    case 'bing':
      return SearchEngineEnum.Bing;
    case 'google':
      return SearchEngineEnum.Google;
    default:
      return SearchEngineEnum.ZetaAlpha;
  }
}

export function mapSearchResultsHits({
  hits,
  next,
  previous,
  search_engine,
  total_hits,
}: SearchPostResponse): SearchResultsData {
  const items = hits.map(deserializeSearchHit);

  return {
    count: total_hits,
    items,
    next: next,
    previous: previous,
    searchEngine: deserializeSearchEngineString(search_engine),
  };
}

export function mapSearchResults(response: SearchPostResponse): SearchResults {
  const { query_string } = response;

  return {
    ...response,
    data: mapSearchResultsHits(response),
    pagesCount: response.number_of_pages,
    query: query_string ?? '',
    totalResults: response.total_hits,
  };
}

export const searchResultsQueryKeys = {
  all: ['searchResults'] as const,
  list: (searchPayload: SearchPayload) =>
    [...searchResultsQueryKeys.lists(), searchPayload] as const,
  lists: () => [...searchResultsQueryKeys.all, 'list'] as const,
};

export const filterNoteHits = (
  hits: RetrievalUnitData[] = []
): RetrievalUnitData[] => {
  return hits.reduce(
    (acc, hit) => (hit.document.type === 'note' ? [...acc, hit] : acc),
    [] as RetrievalUnitData[]
  );
};

export const getDocIdsFromHits = (hits: RetrievalUnitData[] = []): string[] => {
  return hits.reduce((acc: string[], { document: { id, type } }) => {
    return type === HitType.Note ? acc : [...acc, id];
  }, []);
};
