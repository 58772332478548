import { useMemo } from 'react';

import { Nullable } from 'common/utils/assert';

import { Parser } from './Parser';
import { ParserV1 } from './ParserV1';

export const useParser = <T extends object = {}>(content: Nullable<string>) => {
  const parser = useMemo(() => {
    if (ParserV1.isConversation(content)) {
      return new ParserV1<T>(content);
    }

    return new Parser<T>(content);
  }, [content]);

  return { parser };
};
