import { useEffect, useState } from 'react';

import { captureException } from '@sentry/react';
import { compareAsc } from 'date-fns';

import { getChangelogDate, updateChangelogDate } from 'api/authApi';
import { useParsedHostname } from 'common/utils/useParsedHostname';
import { useAuth } from 'containers/Auth/hooks/useAuth';

const getChangelogDateFromLS = () => localStorage.getItem('changelogDate');
export const setChangelogDateToLS = (date: string) =>
  localStorage.setItem('changelogDate', date);

export interface UseChangelogUpdatesReturn {
  changelogUpdates: boolean;
  setLatestChangelogDate: () => Promise<void>;
  updateLatestChangelogDate: () => Promise<void>;
}

export const useChangelogUpdates = (): UseChangelogUpdatesReturn => {
  const { tenant } = useParsedHostname();
  const { isAuthenticated } = useAuth();
  const [changelogDate, setChangelogDate] = useState<string | null>(null);
  const [releaseDate, setReleaseDate] = useState<string | null>(null);
  const [changelogUpdates, setChangelogUpdates] = useState<boolean>(false);

  const updateLatestChangelogDate = async () => {
    setChangelogDateToLS(new Date().toString());
    if (isAuthenticated) {
      await updateChangelogDate();
    }
  };

  const getLatestChangelogDate = async (): Promise<string | null> => {
    let newChangelogDate = getChangelogDateFromLS();

    if (isAuthenticated) {
      const { data } = await getChangelogDate(tenant);
      newChangelogDate = data.changelog_date;
    }

    return newChangelogDate;
  };

  const setLatestChangelogDate = async () => {
    const date = await getLatestChangelogDate();
    setChangelogDate(date);
  };

  useEffect(() => {
    let isSubscribed = true;

    const fetchLatestReleaseDate = async () => {
      try {
        const date = await fetch(
          `${window.env.RELEASE_NOTES_URL}/latest-release-timestamp.txt`,
          {
            headers: {
              'cache-control': 'no-cache',
              pragma: 'no-cache',
            },
            method: 'GET',
          }
        ).then((res) => res.text());

        if (isSubscribed) {
          setReleaseDate(date);
        }
      } catch (error) {
        captureException(error);
        console.error("Couldn't fetch release notes");
      }
    };

    fetchLatestReleaseDate();

    const interval = setInterval(fetchLatestReleaseDate, 900000);

    return () => {
      clearInterval(interval);
      isSubscribed = false;
    };
  }, [setReleaseDate]);

  useEffect(() => {
    let isSubscribed = true;

    (async function getInitialChangelogDate() {
      const date = await getLatestChangelogDate();
      if (isSubscribed) {
        setChangelogDate(date);
      }
    })();

    return () => {
      isSubscribed = false;
    };
  }, [isAuthenticated]);

  useEffect(() => {
    if (!changelogDate) {
      setChangelogUpdates(true);
    } else if (changelogDate && releaseDate) {
      const isOutdated =
        compareAsc(new Date(changelogDate), new Date(releaseDate)) === -1;

      setChangelogUpdates(isOutdated);
    } else {
      setChangelogUpdates(false);
    }
  }, [setChangelogUpdates, changelogDate, releaseDate]);

  return {
    changelogUpdates,
    setLatestChangelogDate,
    updateLatestChangelogDate,
  };
};
