import React, { useEffect } from 'react';

import { StyledEngineProvider } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import * as Sentry from '@sentry/react';
import { createBrowserHistory } from 'history';
import { SnackbarProvider } from 'notistack';
import { Routes } from 'pages/Routes';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { Router } from 'react-router-dom';

import 'i18n/i18n.config';
import { ErrorPageContent } from 'common/components/ErrorPageContent/ErrorPageContent';
import { posthogInit } from 'common/utils/posthog.init';
import { sentryInit } from 'common/utils/sentry.init';
import { Auth } from 'containers/Auth';
import { AppConfirmDialog } from 'containers/ConfirmDialog/AppConfirmDialog';
import { ConfirmDialogProvider } from 'containers/ConfirmDialog/ConfirmDialogProvider';
import { OnboardingWizard } from 'containers/OnboardingWizard/OnboardingWizard';
import { PageProvider } from 'containers/Page/PageProvider';

import { AppTheme } from './AppTheme';

const queryClient = new QueryClient();
const history = createBrowserHistory();

function App() {
  useEffect(() => {
    posthogInit();
    sentryInit(history);
  }, []);

  return (
    <StyledEngineProvider injectFirst>
      <Sentry.ErrorBoundary
        fallback={({ error }) => (
          <ErrorPageContent
            description={error.message ?? 'Something went wrong'}
            title="Error"
          />
        )}
      >
        <QueryClientProvider client={queryClient}>
          <AppTheme>
            <SnackbarProvider maxSnack={3}>
              <Router history={history}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <Auth>
                    <PageProvider>
                      <ConfirmDialogProvider>
                        <Routes />
                        <OnboardingWizard />
                        <AppConfirmDialog />
                        <ReactQueryDevtools initialIsOpen={false} />
                      </ConfirmDialogProvider>
                    </PageProvider>
                  </Auth>
                </LocalizationProvider>
              </Router>
            </SnackbarProvider>
          </AppTheme>
        </QueryClientProvider>
      </Sentry.ErrorBoundary>
    </StyledEngineProvider>
  );
}

export default App;
