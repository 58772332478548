import { useEffect, useState } from 'react';

import { captureException } from '@sentry/react';
import { useSnackbar } from 'notistack';

import { listNotes } from 'api/notesApi/notesApi';
import { NoteDetails } from 'api/notesApi/notesApi.types';
import { useLocationParams } from 'common/hooks/useLocationParams';
import { defined, Optional } from 'common/utils/assert';
import { deserializeAxiosError } from 'common/utils/error';
import { useParsedHostname } from 'common/utils/useParsedHostname';

interface Props {
  onNoteLoaded: (note: NoteDetails) => void;
}

export const useLoadChatNote = ({ onNoteLoaded }: Props) => {
  const { tenant } = useParsedHostname();

  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const { tab } = useLocationParams('tab');
  const { noteId } = useLocationParams('noteId');

  useEffect(() => {
    let isRendered = true;

    const getNote = async () => {
      try {
        if (tab !== 'chat' || !noteId) {
          return null;
        }
        setIsLoading(true);
        const { data } = await listNotes({
          noteIds: [+noteId],
          tenant: tenant,
        });
        setIsLoading(false);
        return defined(data.items[0]);
      } catch (error) {
        captureException(error);
        enqueueSnackbar(deserializeAxiosError(error).message, {
          variant: 'error',
        });
      } finally {
        setIsLoading(false);
      }
    };

    getNote().then((_note: Optional<NoteDetails>) => {
      if (!isRendered || !_note) {
        return;
      }
      onNoteLoaded(_note);
    });

    return () => {
      isRendered = false;
    };
    // should be empty dependencies!
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [noteId]);

  return {
    isLoading,
  };
};
