import React, { FC, useCallback } from 'react';

import SendIcon from '@mui/icons-material/Send';
import { LoadingButton } from '@mui/lab';
import { InputAdornment } from '@mui/material';
import { Form, Formik } from 'formik';
import { FormikHelpers } from 'formik/dist/types';
import { useTranslation } from 'react-i18next';

import { FormTextField } from 'common/components/Fields/FormTextField';

interface MessageInputValues {
  message: string;
}

export interface MessageInputProps {
  disabled?: boolean;
  isLoading?: boolean;
  onSubmit: (value: MessageInputValues['message']) => void;
  placeholder?: string;
}

const initialValues = {
  message: '',
};

export const MessageInput: FC<MessageInputProps> = ({
  disabled,
  isLoading,
  onSubmit,
  placeholder,
}) => {
  const { t } = useTranslation('common');

  const handleSubmit = useCallback(
    (
      values: MessageInputValues,
      { resetForm }: FormikHelpers<MessageInputValues>
    ) => {
      if (!values.message.trim()) {
        return;
      }
      onSubmit(values.message);
      resetForm();
    },
    [onSubmit]
  );

  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit}>
      <Form name="message-form" style={{ width: '100%' }}>
        <FormTextField
          autoComplete="off"
          data-testid="chat-field"
          disabled={disabled}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <LoadingButton
                  color="secondary"
                  data-testid="chat-send-button"
                  disabled={disabled}
                  loading={isLoading}
                  size="small"
                  sx={{ minWidth: 32 }}
                  type="submit"
                >
                  <SendIcon />
                </LoadingButton>
              </InputAdornment>
            ),
          }}
          name="message"
          placeholder={placeholder ?? t('chat.message.input.placeholder')}
          size="small"
          testId="chat-input"
          variant="outlined"
          autoFocus
        />
      </Form>
    </Formik>
  );
};
