import {
  AuthenticationSettingsProtocolEnum,
  ClientIndexSettingsTypeEnum,
  TenantSettings as TenantSettingsResponse,
} from '@zarn/vendor/dist/search';

import {
  SearchFilterDynamicsType,
  TenantSettings,
} from '../tenantSettingsApi.types';

export const tenantSettingsResponseMock: TenantSettingsResponse = {
  authentication_settings: {
    protocol: AuthenticationSettingsProtocolEnum.Oidc,
    protocol_settings: {
      oidc: {
        client_id: 'test-tenant-research-navigator',
        issuer: 'https://login.zeta-alpha.com/realms/azure-test/',
        redirect_uri: 'test',
      },
    },
  },
  client_settings: {
    bot_configurations: [
      {
        bot_identifier: 'chat_with_pdf',
        bot_params: {},
        bot_type: 'default_evidence',
        display_name: 'Default',
      },
      {
        bot_identifier: 'summary',
        bot_params: {},
        bot_type: 'summary',
        display_name: 'Summarize',
      },
      {
        bot_identifier: 'chat_with_tag',
        bot_params: {},
        bot_type: 'chat_with_tag',
        display_name: 'Chat with tag',
      },
      {
        bot_identifier: 'chat_with_qa_widget',
        bot_params: {},
        bot_type: 'chat_with_multiple_docs',
        display_name: 'Chat with QA',
      },
    ],
    document_card: {
      export_options: ['bibtex'],
      sharing_options: ['public'],
    },
    indexes: [
      {
        search_engine_identifier: 'default',
        search_filters_config: [
          {
            display_name: 'Date',
            field_name: 'metadata.DCMI.date',
          },
          {
            display_name: 'Type',
            field_name: 'document_type',
          },
          {
            display_name: 'Test Checkbox',
            faceted: false,
            field_name: 'testCheckbox',
            filter_type: 'checkbox',
            filter_type_settings: {
              checkbox: {
                values: [
                  { label: 'one', value: '1' },
                  { label: 'two', value: '2' },
                  { label: 'three', value: '3' },
                  { label: 'four', value: '4' },
                ],
              },
            },
            url_param: 'test_checkbox',
          },
        ],
        search_sorting_config: [
          {
            display_name: 'Relevance',
            field_name: 'relevance',
            url_param: 'relevance',
          },
          {
            display_name: 'Date',
            field_name: 'date',
            url_param: 'date',
          },
          {
            display_name: 'Citations',
            field_name: 'citations',
            url_param: 'citations',
          },
          {
            display_name: 'Influence',
            field_name: 'authority',
            retrieval_unit: 'document',
            url_param: 'authority',
          },
          {
            display_name: 'Popularity',
            field_name: 'popularity',
            retrieval_unit: 'document',
            url_param: 'popularity',
          },
          {
            display_name: 'Code',
            field_name: 'code',
            retrieval_unit: 'document',
            url_param: 'code',
          },
        ],
        title: 'Test Tenant',
        type: ClientIndexSettingsTypeEnum.Internal,
      },
      {
        search_engine_identifier: 'google',
        search_filters_config: [],
        title: 'All the Web',
        type: ClientIndexSettingsTypeEnum.Federated,
      },
    ],
    pdf_viewer: {
      chat: {
        default_bot_identifier: 'chat_with_pdf',
        extra_bot_identifiers: ['summary'],
      },
    },
    tags_section: {
      chat: {
        default_bot_identifier: 'chat_with_tag',
        extra_bot_identifiers: [],
      },
      export_options: ['bibtex'],
      sharing_options: ['public'],
    },
    white_label_settings: {
      header: {
        background:
          'linear-gradient(92deg, rgba(128, 93, 131, 0.15) 25%, rgba(128, 93, 131, 0.15) 25%, rgba(117, 160, 26, 0.15) 65%, rgba(41, 217, 195, 0.15) 100%)',
      },
      logo: {
        mobile: {
          height: 32,
          src: 'https://freelogopng.com/images/all_img/1686389484tesla-car-logo-png.png',
          width: 32,
        },
        web: {
          height: 52,
          src: 'https://freelogopng.com/images/all_img/1686390747tesla-logo-transparent.png',
          width: 180,
        },
      },
      pages: {
        about: {
          title: 'About',
          url: 'https://zav-release-notes.s3.eu-central-1.amazonaws.com/content.txt',
        },
        contact: {
          title: 'Contact',
          url: 'https://zav-release-notes.s3.eu-central-1.amazonaws.com/content.txt',
        },
        features: {
          title: 'Features',
          url: 'https://zav-release-notes.s3.eu-central-1.amazonaws.com/content.txt',
        },
      },
    },
    widgets: {
      analytics: {
        layout: {
          column: 1,
          column_widths: { md: 6, sm: 6, xl: 6, xs: 12 },
        },
        targets: [],
        title: 'Analytics',
      },
      expert_search: {
        layout: {
          column_widths: {},
          row: 1,
        },
        title: 'Expert search',
      },
      find_authored_by: {
        title: 'Find authored by',
      },
      find_similar_document: {
        title: 'Find similar document',
      },
      qa: { chat: { default_bot_identifier: 'chat_with_qa_widget' } },
      query_analysis: {
        layout: {
          column: 1,
          column_widths: { md: 6, sm: 6, xl: 6, xs: 12 },
          row: 1,
        },
        title: '',
      },
      search_results: {
        layout: {
          column: 2,
          column_widths: { md: 6, sm: 6, xl: 6, xs: 12 },
          row: 1,
        },
      },
      vos_viewer: {
        default_size: 100,
        layout: {
          column_widths: {},
          row: 2,
        },
        title: 'Vos wiewer test',
      },
    },
  },
  features: {
    document_uploads: { status: 'active' },
    people: { status: 'active' },
    recommendations: { status: 'active' },
  },
};

export const testTenantSettingsMock: TenantSettings = {
  chat: {
    botCapabilities: [
      'chat_with_pdf',
      'chat_with_tag',
      'chat_with_qa_widget',
      'summary',
    ],
    botsConfiguration: {
      chat_with_pdf: {
        tenantBotType: 'default_evidence',
      },
      chat_with_qa_widget: {
        tenantBotType: 'chat_with_multiple_docs',
      },
      chat_with_tag: {
        tenantBotType: 'chat_with_tag',
      },
      summary: {
        tenantBotType: 'summary',
      },
    },
    defaultBot: 'chat_with_pdf',
    qaDefaultBot: 'chat_with_qa_widget',
    tagDefaultBot: 'chat_with_tag',
  },
  displayConfiguration: null,
  document: {
    exports: ['bibtex'],
    sharing: ['public'],
  },
  features: {
    documentUploads: {
      status: 'active',
    },
    people: {
      status: 'active',
    },
    recommendations: {
      status: 'active',
    },
  },
  indexCluster: '',
  oidc: {
    authority: 'https://login.zeta-alpha.com/realms/azure-test/',
    clientId: 'test-tenant-research-navigator',
    enabled: true,
  },
  searchFilters: {
    codeFilterEnabled: false,
    countryFilterEnabled: false,
    dateFilterEnabled: true,
    organizationFilterEnabled: false,
    ownerFilterEnabled: false,
    sourceFilterEnabled: false,
    tagFilterEnabled: false,
    typeFilterEnabled: true,
  },
  searchFiltersDynamic: [
    {
      faceted: false,
      fieldName: 'testCheckbox',
      filterType: SearchFilterDynamicsType.Checkbox,
      filterTypeSettings: {
        values: [
          { label: 'one', value: '1' },
          { label: 'two', value: '2' },
          { label: 'three', value: '3' },
          { label: 'four', value: '4' },
        ],
      },
      label: 'Test Checkbox',
      urlParam: 'test_checkbox',
    },
  ],
  searchSorting: {
    chunk: [
      {
        title: 'Relevance',
        urlParam: 'relevance',
        value: 'relevance',
      },
      {
        title: 'Date',
        urlParam: 'date',
        value: 'date',
      },
      {
        title: 'Citations',
        urlParam: 'citations',
        value: 'citations',
      },
    ],
    document: [
      {
        title: 'Relevance',
        urlParam: 'relevance',
        value: 'relevance',
      },
      {
        title: 'Date',
        urlParam: 'date',
        value: 'date',
      },
      {
        title: 'Citations',
        urlParam: 'citations',
        value: 'citations',
      },
      {
        title: 'Influence',
        urlParam: 'authority',
        value: 'authority',
      },
      {
        title: 'Popularity',
        urlParam: 'popularity',
        value: 'popularity',
      },
      {
        title: 'Code',
        urlParam: 'code',
        value: 'code',
      },
    ],
  },
  tabs: {
    default: {
      enabled: true,
      tabTitle: 'Test Tenant',
    },
    google: {
      enabled: true,
      tabTitle: 'All the Web',
    },
    googleScholar: {
      enabled: false,
      tabTitle: '',
    },
  },
  tag: {
    exports: ['bibtex'],
    sharing: ['public'],
  },
  whitelabel: {
    header: {
      background:
        'linear-gradient(92deg, rgba(128, 93, 131, 0.15) 25%, rgba(128, 93, 131, 0.15) 25%, rgba(117, 160, 26, 0.15) 65%, rgba(41, 217, 195, 0.15) 100%)',
    },
    logo: {
      mobile: {
        height: 32,
        src: 'https://freelogopng.com/images/all_img/1686389484tesla-car-logo-png.png',
        width: 32,
      },
      web: {
        height: 52,
        src: 'https://freelogopng.com/images/all_img/1686390747tesla-logo-transparent.png',
        width: 180,
      },
    },
    pages: {
      about: {
        title: 'About',
        url: 'https://zav-release-notes.s3.eu-central-1.amazonaws.com/content.txt',
      },
      contact: {
        title: 'Contact',
        url: 'https://zav-release-notes.s3.eu-central-1.amazonaws.com/content.txt',
      },
      features: {
        title: 'Features',
        url: 'https://zav-release-notes.s3.eu-central-1.amazonaws.com/content.txt',
      },
    },
  },
  widgets: {
    analyticsWidgetEnabled: true,
    expertSearchWidgetEnabled: true,
    findAuthoredByWidgetEnabled: true,
    findSimilarDocumentWidgetEnabled: true,
    layout: {
      columns: [
        {
          sizes: { md: 6, sm: 6, xl: 6, xs: 12 },
          widgets: ['queryAnalysis', 'analytics'],
        },
        {
          sizes: { md: 6, sm: 6, xl: 6, xs: 12 },
          widgets: ['searchResults'],
        },
      ],
      topFullWidthWidgets: ['expertSearch', 'vosViewer'],
    },
    qaWidgetEnabled: true,
    queryAnalysisWidgetEnabled: true,
    searchResultsWidgetEnabled: true,
    vosViewerWidgetEnabled: true,
  },
};

export const ZETA_ALPHA_TENAT_SETTINGS: TenantSettings = {
  chat: {
    botCapabilities: [
      'chat_with_pdf',
      'summary',
      'quizbot',
      'chat_with_tag',
      'chat_with_qa_widget',
    ],
    botsConfiguration: {
      chat_with_pdf: {
        tenantBotType: 'default_evidence',
      },
      chat_with_qa_widget: {
        tenantBotType: 'chat_with_multiple_docs',
      },
      chat_with_tag: {
        tenantBotType: 'chat_with_tag',
      },
      quizbot: {
        tenantBotType: 'quizbot',
      },
      summary: {
        tenantBotType: 'summary',
      },
    },
    defaultBot: 'chat_with_pdf',
    qaDefaultBot: 'chat_with_qa_widget',
    tagDefaultBot: 'chat_with_tag',
  },
  document: {
    exports: ['bibtex'],
    sharing: ['public'],
  },
  features: {
    documentUploads: {
      status: 'active',
    },
    people: {
      status: 'active',
    },
    recommendations: {
      status: 'active',
    },
  },
  indexCluster: '',
  oidc: {
    authority: 'https://login.zeta-alpha.com/realms/azure-test/',
    clientId: 'research-navigator',
    enabled: false,
  },
  searchFilters: {
    codeFilterEnabled: true,
    countryFilterEnabled: true,
    dateFilterEnabled: true,
    organizationFilterEnabled: true,
    ownerFilterEnabled: true,
    sourceFilterEnabled: true,
    tagFilterEnabled: true,
    typeFilterEnabled: true,
  },
  tabs: {
    default: {
      enabled: true,
      tabTitle: 'R&D in AI',
    },
    google: {
      enabled: true,
      tabTitle: 'All the Web',
    },
    googleScholar: {
      enabled: true,
      tabTitle: 'All of Science',
    },
  },
  tag: {
    exports: ['bibtex'],
    sharing: ['public'],
  },
  widgets: {
    analyticsWidgetEnabled: true,
    expertSearchWidgetEnabled: true,
    findAuthoredByWidgetEnabled: true,
    findSimilarDocumentWidgetEnabled: true,
    layout: {
      columns: [
        {
          sizes: { md: 6, sm: 6, xl: 6, xs: 12 },
          widgets: ['QA', 'searchResults'],
        },
        {
          sizes: { md: 6, sm: 6, xl: 6, xs: 12 },
          widgets: [
            'findAuthoredBy',
            'findSimilarDocument',
            'vosViewer',
            'analytics',
            'expertSearch',
            'queryAnalysis',
          ],
        },
      ],
      topFullWidthWidgets: [],
    },
    qaWidgetEnabled: true,
    queryAnalysisWidgetEnabled: true,
    searchResultsWidgetEnabled: true,
    vosViewerWidgetEnabled: true,
  },
};
